<template>

  <!-- profile info  -->
  <section id="tambah-kontak-penanggung-jawab">
    <b-card
      header="Tambah Kontak Penanggung Jawab"
      header-tag="h4"
      header-class="border-bottom mb-2"
    >
      <template v-if="alert">
        <b-alert
          :variant="alertDetail.variant"
          show
        >
          <h4 class="alert-heading">
            {{ alertDetail.title }}
          </h4>
          <div
            v-if="alertDetail.variant === 'danger' && alertDetail.errors !== ''"
            class="alert-body"
          >
            <ul v-if="typeof alertDetail.errors === 'object'">
              <li
                v-for="(item, index) in alertDetail.errors"
                :key="index"
              >
                {{ index }}: {{ item }}
              </li>
            </ul>
            <span v-else>{{ alertDetail.text + ': ' + alertDetail.errors }}</span>
          </div>
          <div
            v-else
            class="alert-body"
          >
            {{ alertDetail.text }}
          </div>
        </b-alert>
      </template>

      <validation-observer ref="formBisnis">
        <form
          id="contactForm"
          @submit.prevent="submitForm"
        >
          <b-row>
            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Nama Penanggung Jawab"
                label-for="nama"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Penanggung Jawab"
                  rules="required"
                >
                  <b-form-input
                    id="nama"
                    v-model="picData.nama"
                    nama="nama"
                    placeholder="Nama Penanggung Jawab"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Nomor Kontak"
                label-for="pic_number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nomor Kontak"
                  rules="required"
                >
                  <b-form-input
                    id="pic_number"
                    v-model="picData.pic_number"
                    nama="pic_number"
                    type="number"
                    placeholder="Nomor Kontak"
                    required
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="NIK"
                label-for="nik"
              >
                <b-form-input
                  id="nik"
                  v-model="picData.nik"
                  nama="nik"
                  type="number"
                  placeholder="NIK"
                  required
                />
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <b-form-group
                label="Deskripsi"
                label-for="deskripsi"
              >
                <b-form-input
                  id="deskripsi"
                  v-model="picData.deskripsi"
                  nama="deskripsi"
                  placeholder="Deskripsi"
                  required
                />
              </b-form-group>
            </b-col>

            <b-col
              class="mb-1"
            >
              <b-button
                type="submit"
                variant="primary"
              >
                Simpan Data Kontak
              </b-button>
              <b-button
                :to="{ path: '/pengajuan/master/kontak' }"
                variant="outline-secondary"
                class="ml-50"
              >
                Kembali
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-card>
  </section>
  <!--/ profile info  -->

</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton, BFormInput, BRow, BCol, BCard, BFormGroup, BAlert,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BFormInput,
    BFormGroup,
    BButton,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      required,
      userData: getUserData(),
      master_pic_id: '',
      picData: {},
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 110 || role < 99) {
      next('/')
    } else {
      next()
    }
    if (!to.params.id) {
      next('/')
    } else {
      next()
    }
  },
  created() {
    this.master_pic_id = Number(this.$route.params.id)
  },
  mounted() {
    this.$http.get('master/master-pic/get', {
      params: {
        token: localStorage.getItem('userToken'),
        master_pic_id: this.master_pic_id,
      },
    })
      .then(res => {
        if (res.data.status === 'success') {
          this.picData = res.data.data
        }
      })
  },
  methods: {
    submitForm() {
      this.$refs.formBisnis.validate().then(success => {
        if (success) {
          this.simpanTemplate()
        }
      })
    },
    simpanTemplate() {
      const metaUtama = {
        master_pic_id: this.master_pic_id,
        pic_name: this.picData.nama,
        pic_number: this.picData.pic_number,
      }

      if (this.picData.nik) {
        metaUtama.nik = this.picData.nik
      }
      if (this.picData.deskripsi) {
        metaUtama.deskripsi = this.picData.deskripsi
      }

      this.$http.post('/master/master-pic/add', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.alert = true
            this.alertDetail = {
              variant: 'success',
              title: 'Data Saved',
              text: res.data.message,
            }

            setTimeout(() => {
              this.alert = false
            }, 2000)
          } else {
            this.alert = true
            this.alertDetail = {
              variant: 'danger',
              title: 'Error Found',
              text: `${res.data.message}: ${res.data.error}`,
            }
          }
        })
        .catch(error => {
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.message !== '') ? error.response.data.message : error,
            errors: (error.response.data.error !== '') ? error.response.data.error : error,
          }
        })
    },
  },
}
</script>
